import React from 'react';

const BtnClose = ({ width = 24, height = 24, ...props }) => {
  return (
    <a {...props}>
        <span>
            <svg id="close" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
                <path id="Shape" d="M10.384,12,.336,22.064a1.12,1.12,0,0,0,0,1.6,1.12,1.12,0,0,0,1.6,0L12,13.616,22.064,23.664a1.131,1.131,0,0,0,1.6-1.6L13.616,12,23.664,1.936a1.12,1.12,0,0,0,0-1.6,1.12,1.12,0,0,0-1.6,0L12,10.384,1.936.336a1.12,1.12,0,0,0-1.6,0,1.12,1.12,0,0,0,0,1.6Z" fill="currentColor"/>
            </svg>
        </span>
    </a>
  )
}

export default BtnClose;
